<template>
  <div class="visibility-permission-familly style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-progress-circular
            class="ml-2"
            v-if="loadingFamillysPermissions"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="search"
              @changeSearchValue="handleSearch"
              hiddenContent="true"
              placeHolder="Rechercher par utilisateur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="blocks">
        <div class="permission-hearder">
          <div class="title">Utilisateurs</div>
          <div class="list-interface fix-height-slide-user">
            <v-list class="list-category">
              <v-list-item-group>
                <v-list-item
                  button
                  v-for="user in famillysPermissions"
                  :key="user.id"
                  @click.prevent="handleClickUser(user)"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-icon
                    v-if="
                      selectedUser && selectedUser.full_name == user.full_name
                    "
                  >
                    <font-awesome-icon class="icon-color-cdg" icon="check" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="capitalize">
                        {{ user.full_name }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group></v-list
            >
          </div>
        </div>
        <div class="permission-hearder-sous-interface" v-if="selectedUser">
          <div class="permission-user" v-if="selectedUser">
            <div class="title">Familles</div>
            <div class="list-interface">
              <div class="autorisation-permission ">
                <div class="row">
                  <div class="col-1">
                    <v-checkbox
                      label=""
                      class="checked-global"
                      color="#704ad1"
                      :value="computedValueCheck(selectedUser.famillies)"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      v-if="checkPermission('GPHTAPUS')"
                      @change="
                        assignTousPermission($event, selectedUser.famillies)
                      "
                    ></v-checkbox>
                  </div>
                  <div class="col-9">Tous</div>
                </div>
              </div>
              <div
                v-for="familly in getListFamilleConfigFrias"
                :key="familly.id"
                class="selected-role"
              >
                <div class="autorisation-permission ">
                  <div class="row">
                    <div class="col-1">
                      <v-checkbox
                        label=""
                        class="checked-global"
                        color="#704ad1"
                        :value="computedValue(familly)"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                        v-if="checkPermission('GPHTAFTP')"
                        @change="assignFamilly(familly)"
                      ></v-checkbox>
                    </div>
                    <div class="col-9">
                      {{ familly.familly }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      selectedUser: null,
      selectedPage: null,
      getListePermission: [],
      search: null
    };
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  methods: {
    ...mapActions([
      'getAllFamillePermission',
      'assignFamillyToUser',
      'removeFamillyToUser',
      'fetchAllFammilleConfig'
    ]),
    handleSearch() {
      this.handleFilter();
    },
    handleFilter() {
      this.getAllFamillePermission({ search: this.search });
    },
    assignFamilly(familly) {
      let payload = {
        user_id: this.selectedUser.id,
        familly_id: familly.id
      };
      if (this.computedValue(familly) == true) {
        this.removeFamillyToUser(payload);
      } else {
        this.assignFamillyToUser(payload);
      }
    },
    async assignTousPermission(event, famillies) {
      if (event == true) {
        for (let i = 0; i < this.getListFamilleConfigFrias.length; i++) {
          let payload = {
            user_id: this.selectedUser.id,
            familly_id: this.getListFamilleConfigFrias[i].id
          };
          await this.assignFamillyToUser(payload);
        }
      } else {
        for (let i = 0; i < famillies.length; i++) {
          let payload = {
            user_id: this.selectedUser.id,
            familly_id: famillies[i].id
          };
          await this.removeFamillyToUser(payload);
        }
      }
    },
    handleClickUser(user) {
      this.selectedUser = user;
      this.selectedPage = null;
    }
  },
  computed: {
    ...mapGetters([
      'loadingFamillysPermissions',
      'checkPermission',
      'getListFamilleConfigFrias',
      'famillysPermissions',
      'errorFamillysPermissions'
    ]),
    computedValue() {
      return function(familly) {
        if (
          this.selectedUser.famillies.filter(item => item.id == familly.id)
            .length
        ) {
          return true;
        }
        return false;
      };
    },
    computedValueCheck() {
      return function(famillies) {
        if (famillies.length == this.getListFamilleConfigFrias.length) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    this.fetchAllFammilleConfig({ blocked: false });
    this.handleFilter();
  }
};
</script>

<style lang="scss" scoped>
.Search-style-component {
  .search-box {
    // position: relative;
    // top: 0;
    // right: 0px;
    // background-color: #842828;
    // padding: 5px;
    // color: #871c1c;
    // border: none;
    // border-radius: 19px;
    // margin-bottom: 30px;s
    position: relative;
    top: 0;
    right: 0px;
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
.visibility-permission-familly {
  .checked-global {
    margin-top: 0px;
  }
  // margin-top: 10px;
  // display: flex;
  // width: 100%;
  // height: 100%;
  // background-color: #f5f5f5c7;
  // padding: 10px;
  // border-radius: 15px;
  .box-content {
    padding-top: 0px !important;
  }
  .blocks {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5c7;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 0px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .custom-list-group {
    overflow-y: auto;
    // max-height: calc(100vh - 330px);
    // height: calc(100vh - 330px);
  }
  .permission-hearder {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 0px;
    width: 40%;
    max-width: 40%;
    margin-right: 10px;
  }
  .permission-hearder-sous-interface {
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 0px;
    width: 60%;
    margin-right: 10px;
  }
  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 0px;
    width: 50%;
    margin-right: 10px;
  }
  .permission-hearder {
    width: 40%;
    margin-right: 10px;
  }
  .body-permission {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 0px;
    width: 27%;
  }
  .permission-body {
    width: 75%;
    display: flex;

    .permission-select-component {
      margin-bottom: 10px;
    }
    .permission-user,
    .permission-interface {
      width: 37%;
      padding: 5px;
    }
    .permission-autorisation {
      width: 25%;
      padding: 5px;
    }
  }
  .position-btn {
    position: relative;
    text-align-last: center;
    padding-top: 6px;
    .config-coef-btn {
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss">
.visibility-permission-familly {
  // width: 30%;
  /* width */
  .fix-height-slide-user {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 335px);
    padding-right: 9px;
  }

  .fix-height-slide-user::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .fix-height-slide-user::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .fix-height-slide-user::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .fix-height-slide-user::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }

  .description-column {
    width: 76%;
    float: left;
  }
  .info-column {
    width: 10%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }
  .permission-hearder {
    display: block;
    // overflow-y: scroll !important;
    height: calc(100vh - 270px);
  }
  .permission-hearder-sous-interface {
    width: 50%;
    margin-right: 10px;
    height: calc(100vh - 263px);
  }

  .permission-hearder-autorisations {
    display: block;
    overflow-y: scroll !important;
    height: 10px;
  }
  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
  .check-permission {
    accent-color: #4d4bac;
    height: 20px; /* not needed */
    width: 20px; /* not needed */
  }
}
</style>
